// App.js
import React from "react";
import Header from "./Header";
import HomePage from "./HomePage";
import Footer from "./Footer";
import { ThemeProvider } from "./ThemeContext";
import { Route, Routes } from "react-router-dom";
import ContactPage from "./ContactPage";
import Project from "./Project";
import Achievements from "./Achievements";
import Work from "./Work";
import ScrollToTop from "./components/ScrollToTop";
import Coding from "./Coding";

function App() {
  return (
    <>
      <ThemeProvider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/project" element={<Project />} />
          <Route path="/work" element={<Work />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/coding" element={<Coding />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
