import React, { useEffect, useState } from "react";
import { CodeBracketIcon } from "@heroicons/react/24/solid";
import { IconSun, IconMoon } from "@tabler/icons-react";

import {
  IconBrandGithub,
  IconTrophy,
  IconBriefcase,
  IconHome,
  IconPhone,
  IconTerminal2,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import { FloatingDock } from "../ui/FloatingDoc";
const FloatingBar = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!localStorage.theme &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    } else {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      setTheme("light");
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  };
  const links = [
    {
      title: "Home",
      icon: (
        <IconHome className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/",
    },

    {
      title: "Projects",
      icon: (
        <IconTerminal2 className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/project",
    },
    {
      title: "Work",
      icon: (
        <IconBriefcase className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/work",
    },
    {
      title: "Awards & Achievements",
      icon: (
        <IconTrophy className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/achievements",
    },
    {
      title: "Contact Me",
      icon: (
        <IconPhone className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/contact",
    },

    {
      title: "Twitter",
      icon: (
        <IconBrandLinkedin className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.linkedin.com/in/gauravs-2k/",
    },
    {
      title: "GitHub",
      icon: (
        <IconBrandGithub className="w-full h-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://github.com/Gauravs-2k",
    },
    {
      title: "Coding",
      icon: <CodeBracketIcon className="w-full h-full" />,
      href: "https://www.gauravshivaprasad.com/coding",
    },
    {
      title: "Toggle Theme",
      icon:
        theme === "light" ? (
          <IconSun className="w-full h-full text-neutral-500 dark:text-neutral-300" />
        ) : (
          <IconMoon className="w-full h-full text-neutral-500 dark:text-neutral-300" />
        ),
      onClick: toggleTheme,
    },
  ];

  return (
    <div
      style={{
        position: "fixed", // Keeps it fixed at the bottom
        bottom: 20, // Aligns it to the bottom of the screen
        left: 0,
        width: "100%", // Spans the full width for centering
        display: "flex", // Enables flexbox for horizontal centering
        justifyContent: "center", // Centers content horizontally
        zIndex: 50, // Ensures it appears above other content
      }}
    >
      <FloatingDock
        mobileClassName="translate-y-20" // Only for demo, remove for production
        items={links}
      />
    </div>
  );
};

export default FloatingBar;
