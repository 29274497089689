import React from "react";
import { Grid, Typography, Card, Box } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Spotlight } from "./ui/Spotlight";
function Project() {
  const proj = [
    {
      image: [
        "projects/pharmit/p1.png",
        "projects/pharmit/p2.png",
        "projects/pharmit/p4.png",
        "projects/pharmit/p5.png",
      ],
      tech_tag: ["React", "MySQL", "node", "Express.js"],
      title: "Hr-Payroll - PharmIT",
      titinfo: [
        "Comprehensive HR and Payroll Integration: Developed a solution that merges the functionalities of Zoho Hiring and Razorpay Payroll, streamlining recruitment and payroll processes.",
        "Automated Hiring Workflow: Implemented automated hiring workflows, including job postings, candidate tracking, and offer management, enhancing recruitment efficiency.",
        "Seamless Payroll Management: Integrated payroll processing with automated salary disbursement, tax calculations, and compliance handling to reduce manual effort.",
        "User-Friendly and Scalable: Built an intuitive, scalable platform that supports growing teams, providing robust data security and compliance features.",
      ],
      link: "https://hrtest.pharmit.in/",
    },
    {
      image: [
        "projects/Refease/RefEase 1.png",
        "projects/Refease/R2.png",
        "projects/Refease/R3.png",
        "projects/Refease/R4.png",
        "projects/Refease/R5.png",
        "projects/Refease/R6.png",
      ],
      title: "Refease - Bytecrafts",
      tech_tag: ["React", "Mongodb", "node", "Express.js"],
      titinfo: [
        "Developed a platform that revolutionizes dental imaging by enabling clinics to capture, store, and share X-rays seamlessly. The solution enhances doctor-patient collaboration and reduces paperwork with a digital-first approach.",
        "Ensured patient data confidentiality through HIPAA compliance and granular access control, safeguarding medical records and images with top-notch security standards.",
        "Facilitated efficient clinic-doctor collaboration by enabling clinics to onboard doctors, manage patient records, and assign X-rays for prompt review, improving the overall workflow.",
        "Upcoming feature includes integrated billing and invoicing, simplifying financial management for clinics and doctors within the platform.",
      ],
      link: "https://www.refease.in/",
    },
    {
      image: [
        "projects/NamNews/N1.png",
        "projects/NamNews/N2.png",
        "projects/NamNews/N3.png",
        "projects/NamNews/N4.png",
        "projects/NamNews/N5.png",
      ],
      title: "NamNews",
      tech_tag: [
        "Next.js",
        "React Native",
        "Xcode",
        "Mongodb",
        "node",
        "Express.js",
      ],
      titinfo: [
        "Developed a scalable digital platform that enables local newspapers to establish an online presence, manage digital content, and expand audience reach through social media integration.",
        "Enhanced visibility and engagement by providing tools for tracking reader activity and analyzing website traffic through comprehensive dashboards.",
        "Created new revenue opportunities for newspapers through subscription models and ad placements, helping generate passive income and sustain operations.",
        "Supported sustainable digital growth by empowering newspapers to build their brand, engage audiences, and mitigate the decline in print readership.",
      ],
      link: "https://www.youtube.com/watch?v=ao3lY84Tbko&t=36s",
    },
    {
      image: [
        "projects/Marketplace/G1.png",
        "projects/Marketplace/G2.png",
        "projects/Marketplace/G3.png",
        "projects/Marketplace/G4.png",
        "projects/Marketplace/G5.png",
        "projects/Marketplace/G6.png",
        "projects/Marketplace/G7.png",
      ],
      title: "GiveMore – Community Marketplace for COVID-19 (January 2019)",
      tech_tag: ["Flutter", "Mongodb"],
      titinfo: [
        "Designed and developed a cross-platform mobile application using Flutter and Firebase to connect donors with the financially underprivileged, medical workers, NGOs, and hospitals.",
        "Implemented real-time data synchronization, user authentication, and inventory management.",
        "Integrated an IBM Watson virtual assistant to provide users with instant answers to COVID-related queries and assist distributors with optimized navigation routes.",
        "Secured a Top 16 finalist position out of 26,478 participants in a national competition, showcasing innovation and community impact.",
        "The platform facilitated safe and efficient distribution of essential supplies, promoted temporary employment opportunities, and adhered to social distancing guidelines, contributing significantly to community resilience during the pandemic.",
      ],
      link: "https://www.youtube.com/watch?v=ao3lY84Tbko&t=36s",
    },
  ];

  return (
    <div className="relative w-full bg-gray-900 text-white">
      {/* Header Section */}
      <div className="h-[40rem] w-full rounded-md flex md:items-center md:justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          fill="white"
        />
        <div className="p-4 max-w-7xl mx-auto relative z-10 w-full pt-20 md:pt-0">
          <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
            Projects
          </h1>
          <p className="mt-4 font-normal text-base text-neutral-300 max-w-lg text-center mx-auto">
            A collection of ideas brought to life! From sleek apps to clever
            tools, explore my journey of turning creativity into impactful
            solutions. 🚀✨
          </p>
        </div>
      </div>
      {/* Project List */}
      <div className="relative bg-black pt-16 pb-10">
        {proj.map((por, index) => (
          <div
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center mx-auto max-w-7xl gap-8 px-4 mb-12`}
          >
            {/* Carousel Section */}
            <div className="flex-1">
              <Carousel
                showStatus={false}
                infiniteLoop
                useKeyboardArrows
                dynamicHeight
                autoPlay
                showThumbs={false}
              >
                {por?.image?.map((img, imgIndex) => (
                  <div
                    key={imgIndex}
                    className="overflow-hidden rounded-lg shadow-md"
                  >
                    <img
                      src={img}
                      alt={por.title}
                      className="w-full h-96 object-contain"
                    />
                    <p className="legend">{por.title}</p>
                  </div>
                ))}
              </Carousel>
            </div>

            {/* Project Details */}
            <div className="flex-1 bg-gray-800 p-6 rounded-lg border border-white">
              <h3 className="text-powderblue text-xl mb-4">{por.title}</h3>
              <div className="mb-4">
                {por.tech_tag.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    className="inline-block px-3 py-1 bg-gray-300 text-gray-800 text-sm font-semibold rounded-full mr-2"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <ul className="mb-4 list-disc list-inside">
                {por.titinfo.map((info, i) => (
                  <li key={i}>{info}</li>
                ))}
              </ul>
              <a
                href={por.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-lightblue underline hover:text-blue-300"
              >
                Know More.....
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Project;
