import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism"; // VS Code-like theme

const Coding = () => {
  const [solutions, setSolutions] = useState([]); // List of questions
  const [selectedCode, setSelectedCode] = useState(""); // Code to display
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [copySuccess, setCopySuccess] = useState(""); // Copy success feedback

  // Fetch solutions metadata
  useEffect(() => {
    fetch("/coding.json")
      .then((res) => res.json())
      .then((data) => setSolutions(data));
  }, []);

  // Fetch code content dynamically
  const fetchCode = async (codePath) => {
    const response = await fetch(codePath);
    const code = await response.text();
    setSelectedCode(code);
    setIsModalOpen(true); // Open modal after fetching code
  };

  // Close Modal Function
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCode("");
    setCopySuccess("");
  };

  // Copy code to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedCode);
    setCopySuccess("Code copied!");
    setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
  };

  return (
    <div className="p-6 pb-32 transition-colors duration-300 bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-white">
      <h1 className="mb-4 text-2xl font-bold">LeetCode Solutions</h1>

      {/* Table of Questions */}
      <table className="w-full border border-collapse border-neutral-200 dark:border-neutral-700">
        <thead>
          <tr className="bg-neutral-100 dark:bg-neutral-800">
            <th className="p-2 border border-neutral-200 dark:border-neutral-700">
              ID
            </th>
            <th className="p-2 border border-neutral-200 dark:border-neutral-700">
              Title
            </th>
            <th className="p-2 border border-neutral-200 dark:border-neutral-700">
              Tags
            </th>
            <th className="p-2 border border-neutral-200 dark:border-neutral-700">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {solutions.map((sol) => (
            <tr
              key={sol.id}
              className="transition-colors duration-200 hover:bg-neutral-200 dark:hover:bg-neutral-800"
            >
              <td className="p-2 text-center border border-neutral-200 dark:border-neutral-700">
                {sol.id}
              </td>
              <td className="p-2 border border-neutral-200 dark:border-neutral-700">
                {sol.title}
              </td>
              <td className="p-2 border border-neutral-200 dark:border-neutral-700">
                {sol.tags.join(", ")}
              </td>
              <td className="p-2 text-center border border-neutral-200 dark:border-neutral-700">
                <button
                  onClick={() => fetchCode(sol.code_path)}
                  className="text-blue-600 transition-colors dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
                >
                  👁️
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-neutral-900/70 dark:bg-black/70 backdrop-blur-sm"
          onClick={closeModal}
        >
          <div
            className="relative w-full max-w-3xl p-6 rounded-lg shadow-lg bg-neutral-50 dark:bg-neutral-800"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-neutral-800 dark:text-neutral-200">
                Solution Code
              </h2>
              <button
                onClick={closeModal}
                className="transition-colors text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200"
              >
                ✖
              </button>
            </div>

            <div className="relative">
              <SyntaxHighlighter
                language="python"
                style={oneDark}
                showLineNumbers={true}
                wrapLongLines={true}
                customStyle={{
                  borderRadius: "5px",
                  fontSize: "14px",
                  background: "#1a1a1a",
                }}
              >
                {selectedCode}
              </SyntaxHighlighter>

              <button
                onClick={copyToClipboard}
                className="absolute right-2 top-8 inline-flex h-8 -translate-y-1/2 items-center justify-center gap-1 rounded-md bg-neutral-700 dark:bg-neutral-600 px-2.5 py-1.5 text-sm font-medium text-white hover:bg-neutral-600 dark:hover:bg-neutral-700 transition-colors duration-200"
              >
                <span>{/* ...existing SVG code... */}</span>
                {copySuccess ? "Copied" : "Copy"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Coding;
