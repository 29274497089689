import React from "react";
import { Grid, Typography, Card, Button } from "@mui/material";
import { BackgroundLines } from "./ui/BackgroundLines";

function Achievements() {
  const publication = [
    {
      title: "Automated public transport system using IoT",
      info: "The adaption of new technologies and application of IoT has solved many critical problems. Create an efficient and data-driven ecosystem. This paper aims to provide commuters with an organized, efficient, and cashless public transportation system. This work aims to give commuters a clear idea about the arrival and departure of public buses. It also makes the payment system efficient, effortless, and cashless. The buses equipped with this technology will provide more options to plan organized trips. Fleets will experience less disorganization due to the data-driven technology they will use.",
      link: "https://link.springer.com/chapter/10.1007/978-981-16-3097-2_28",
    },
    {
      title: "Automated Plant Robot",
      info: "This work is a Raspberry Pi-based prototype that has been built while keeping both the Indian agricultural scenario and hobbyist backyard gardeners in mind. It aims not only to act as a solution to conventional method of growing plants but also to approach along the lines of sustainable development. With our project, we are also aiming to optimize the amount of resources required to grow a plant. The model acts as the brain of the plant by providing directions to the technical implementations of the setup such as an attached pair of wheels and a water pump to act according to the requirement. This prototype could be a possible solution to majority of the urban population planning to have potted plants and greenery in their apartment but with space, time and effort constraints. Further details like implementation, advantages and future are mentioned in the next sections.",
      link: "https://link.springer.com/chapter/10.1007/978-981-15-4218-3_11",
    },
  ];
  const achievements = [
    {
      ach: "Crack the COVID-19 Crisis (March 2021)",
      inf: "Top 16 finalists out of nearly 4000 teams in Hackathon Nascoom, IBM, India",
    },
    {
      ach: "Solve4Bharath (September 2020)",
      inf: "Finalists at hackathon held by Indian Institute of Science IISc, Bangalore, India",
    },
    {
      ach: "MLH Build Day (August 2019)",
      inf: "Winner of Best Innovation hack, Vellore Institute of Technology Amaravathi, India",
    },
  ];

  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="min-h-screen transition-colors duration-300 bg-white dark:bg-neutral-900">
      <div className="w-full pb-12 text-center transition-colors duration-300 bg-neutral-100 dark:bg-neutral-800">
        <BackgroundLines className="flex flex-col items-center justify-center w-full px-4">
          <h2 className="relative z-20 py-2 font-sans text-2xl font-bold tracking-tight text-center text-transparent bg-clip-text bg-gradient-to-b from-neutral-900 to-neutral-700 dark:from-neutral-600 dark:to-white md:text-4xl lg:text-7xl md:py-10">
            Highlights of Excellence
          </h2>
          <p className="max-w-xl mx-auto text-sm text-center md:text-lg text-neutral-700 dark:text-neutral-400">
            A curated collection of achievements, including publications,
            awards, and hackathon wins, showcasing a journey of innovation,
            skill, and excellence
          </p>
        </BackgroundLines>

        <div className="flex flex-col items-center mt-10 space-y-8">
          <h3 className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
            PUBLICATIONS
          </h3>
          {publication.map((pub, index) => (
            <div
              key={index}
              className="w-full max-w-2xl p-6 text-center rounded-lg shadow-md bg-neutral-200 dark:bg-neutral-700"
            >
              <h4 className="mb-4 text-xl font-semibold text-cyan-600 dark:text-cyan-400">
                {pub.title}
              </h4>
              <p className="mb-4 text-neutral-800 dark:text-neutral-200">
                {pub.info}
              </p>
              <button
                onClick={() => handleButtonClick(pub.link)}
                className="flex items-center gap-2 px-4 py-2 font-medium transition-all duration-300 rounded-lg text-neutral-100 bg-neutral-800 dark:bg-neutral-700 hover:bg-neutral-700 dark:hover:bg-neutral-600 group"
              >
                Read More
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                </svg>
              </button>
            </div>
          ))}
          <h3 className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
            ACHIEVEMENTS
          </h3>
          {achievements.map((achi, key) => (
            <div
              key={key}
              className="w-full max-w-2xl p-6 text-center rounded-lg shadow-md bg-neutral-200 dark:bg-neutral-700"
            >
              <h4 className="mb-4 text-xl font-semibold text-cyan-600 dark:text-cyan-400">
                {achi.ach}
              </h4>
              <p className="text-neutral-800 dark:text-neutral-200">
                {achi.inf}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Achievements;
