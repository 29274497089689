import React from "react";
import { TypewriterEffectSmooth } from "./ui/TypeWriter";

function ContactPage() {
  const openLinkedInProfile = () => {
    window.open("https://www.linkedin.com/in/gauravs-2k/", "_blank");
  };

  const openGitHubProfile = () => {
    window.open("https://github.com/Gauravs-2k", "_blank");
  };

  const words = [
    {
      text: "The Inbox Is Open, Operators Are Standing By (That’s Me) 🕹️",
    },
  ];

  return (
    <div className="min-h-screen pb-10 transition-colors duration-300 bg-white dark:bg-neutral-900 text-neutral-800 dark:text-white">
      {/* Header Section */}
      <div className="pt-12 mb-12 text-center">
        <div className="flex flex-col items-center justify-center h-[20rem]">
          <TypewriterEffectSmooth words={words} />
        </div>
        <p className="max-w-2xl mx-auto text-lg text-neutral-600 dark:text-neutral-300">
          Got a question or want to work together? I'd love to hear from you!
          Use the contact details below or fill out the form to get in touch.
        </p>
      </div>

      {/* Contact Section */}
      <div className="flex flex-wrap justify-center gap-8">
        {/* Email */}
        <a
          href="mailto:shivaprasad.gaurav@gmail.com"
          className="flex items-center justify-center px-6 py-3 transition duration-300 bg-transparent border rounded-lg shadow-md border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800"
        >
          <span className="mr-3 text-xl">📧</span>
          <span className="text-neutral-800 dark:text-neutral-200">
            shivaprasad.gaurav@gmail.com
          </span>
        </a>

        {/* Phone */}
        <div className="flex items-center justify-center px-6 py-3 transition duration-300 bg-transparent border rounded-lg shadow-md border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800">
          <span className="mr-3 text-xl">📞</span>
          <span className="text-neutral-800 dark:text-neutral-200">
            +91-6362718708 , +13129188057
          </span>
        </div>

        {/* CV Download */}
        <button className="flex items-center justify-center px-6 py-3 transition duration-300 bg-transparent border rounded-lg shadow-md border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800">
          <span className="mr-3 text-xl">📄</span>
          <span className="text-neutral-800 dark:text-neutral-200">
            Download CV
          </span>
        </button>

        {/* Social Links */}
        <div className="flex gap-4">
          <button
            onClick={openLinkedInProfile}
            className="p-3 transition duration-300 bg-transparent border rounded-full border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            🔗{" "}
            <span className="text-neutral-800 dark:text-neutral-200">
              LinkedIn
            </span>
          </button>
          <button
            onClick={openGitHubProfile}
            className="p-3 transition duration-300 bg-transparent border rounded-full border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            🐙{" "}
            <span className="text-neutral-800 dark:text-neutral-200">
              GitHub
            </span>
          </button>
        </div>
      </div>

      {/* Form Section */}
      <div className="px-4 mt-12">
        <h2 className="mb-6 text-4xl font-bold text-center text-neutral-800 dark:text-white">
          Send Me a Message
        </h2>
        <p className="mb-8 text-center text-neutral-600 dark:text-neutral-300">
          Have something specific in mind? Let me know your thoughts, and I'll
          get back to you soon!
        </p>
        <div className="max-w-3xl mx-auto space-y-6">
          {/* Form Fields */}
          <div className="flex flex-col items-center p-4 border rounded-lg md:flex-row border-neutral-200 dark:border-neutral-700">
            <p className="mb-2 mr-4 md:mb-0 text-neutral-700 dark:text-neutral-300">
              Hey, my name is
            </p>
            <input
              type="text"
              placeholder="Your Name"
              className="flex-1 p-2 bg-transparent border-b outline-none text-neutral-800 dark:text-white border-neutral-300 dark:border-neutral-600 focus:border-blue-500"
            />
            <p className="mt-2 ml-4 md:mt-0 text-neutral-700 dark:text-neutral-300">
              and I'm looking for
            </p>
          </div>

          {/* Email Input */}
          <div className="flex flex-col items-center p-4 border rounded-lg md:flex-row border-neutral-200 dark:border-neutral-700">
            <p className="mb-2 mr-4 md:mb-0 text-neutral-700 dark:text-neutral-300">
              Get in touch with me at
            </p>
            <input
              type="email"
              placeholder="Your Email Here"
              className="flex-1 p-2 bg-transparent border-b outline-none text-neutral-800 dark:text-white border-neutral-300 dark:border-neutral-600 focus:border-blue-500"
            />
          </div>

          {/* Message Input */}
          <div className="flex flex-col p-4 border rounded-lg border-neutral-200 dark:border-neutral-700">
            <textarea
              placeholder="Your Message Here..."
              className="w-full p-2 bg-transparent border-b outline-none text-neutral-800 dark:text-white border-neutral-300 dark:border-neutral-600 focus:border-blue-500"
              rows="4"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button className="w-full py-3 font-bold text-white transition duration-300 rounded-lg bg-neutral-800 dark:bg-neutral-700 hover:bg-neutral-700 dark:hover:bg-neutral-600">
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
