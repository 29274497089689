import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  Button,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeHeader from "./HomeComponents.jsx/HomeHeader";
import { useNavigate } from "react-router-dom";
import { BackgroundGradient } from "./ui/BackgroundGradient";
import { Modal, ModalTrigger } from "./ui/ModalAnimation";

export default function HomePage() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const cards = [
    {
      title: "Publications",
      image: "Publication.jpg",
      points: [
        "Automated public transport system using IoT",
        "Automated Plant Robot",
      ],
      buton: "Read More",
      path: "/achievements",
    },
    {
      title: "Projects",
      image: "Project.jpg",
      points: [
        "Refease",
        "Namnews",
        "SmartASL - Real Time sign language Interpreter",
      ],
      buton: "Read More",
      path: "project",
    },
    {
      title: "Awards and Achievements",
      image: "Awards.jpg",
      points: [
        "Top 16 finalists out of nearly 4000 teams (March 2021)",
        "Winner of the Best Innovation hack on MLH Build Day (August 2019).",
        "Finalist at Solve4Bharath Hackathon",
      ],
      buton: "Read More",
      path: "achievements",
    },
    {
      title: "Education ",
      image: "Education.jpg",
      points: [
        "Master’s in Computer Science Illinois Institute of Technology, May 2026",
        "Bachelor of Technology in Computer Science and Engineering Vellore Institute of Technology - AP, May 2022 | GPA: 8.78",
      ],
    },
    {
      title: "WORK EXPERIENCE ",
      image: "Work.jpg",
      points: [
        "Currently working as Hawk’s Nest Graduate Assistant, Part-Time, Illinois Institute of Technology, Chicago, Illinois (September 2024 – Present)",
      ],
      buton: "Know More",
      path: "work",
    },
  ];
  const handleMouseEnter = (index) => {
    setHover((prevHover) => ({ ...prevHover, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHover((prevHover) => ({ ...prevHover, [index]: false }));
  };

  const chips = [
    {
      title: "Frontend And Web Development",
      values: [
        {
          name: "MERN",
          icon: "assets/icons/mern.png",
        },
        {
          name: "React",
          icon: "assets/icons/react.png",
        },
        {
          name: "Next.js",
          icon: "assets/icons/next.svg",
        },
        {
          name: "HTML5",
          icon: "assets/icons/html.svg",
        },
        {
          name: "CSS3",
          icon: "assets/icons/css.svg",
        },
        {
          name: "Javascript",
          icon: "assets/icons/js.svg",
        },
        {
          name: "Bootstrap",
          icon: "assets/icons/bootstrap.svg",
        },
        {
          name: "Tailwind CSS",
          icon: "assets/icons/tailwind.svg",
        },
      ],
    },
    {
      title: "Mobile Application Development",
      values: [
        {
          name: "React Native",
          icon: "assets/icons/reactnative.svg",
        },
        {
          name: "Xcode",
          icon: "assets/icons/xcode.svg",
        },
        {
          name: "Flutter",
          icon: "assets/icons/flutter.svg",
        },
        {
          name: "Android Studio",
          icon: "assets/icons/android-studio.svg",
        },
      ],
    },
    {
      title: "Backend And Database Management",
      values: [
        {
          name: "MySQL",
          icon: "assets/icons/mysql.svg",
        },
        {
          name: "Oracle",
          icon: "assets/icons/oracle.svg",
        },
        {
          name: "MongoDB",
          icon: "assets/icons/mongodb.svg",
        },
        {
          name: "Node.js",
          icon: "assets/icons/nodejs.svg",
        },
        {
          name: "Express.js",
          icon: "assets/icons/express.svg",
        },
        {
          name: "PostgreSQL",
          icon: "assets/icons/postgresql-plain.svg",
        },
      ],
    },
    {
      title: "Cloud And DevOps Tools",
      values: [
        {
          name: "AWS",
          icon: "assets/icons/aws.svg",
        },
        {
          name: "Vercel",
          icon: "assets/icons/vercel.svg",
        },
        {
          name: "Render",
          icon: "assets/icons/render.png",
        },
        {
          name: "Microsoft Clarity",
          icon: "assets/icons/microsoft.svg",
        },
        {
          name: "Google cloud platform",
          icon: "assets/icons/google-cloud.svg",
        },
        {
          name: "CI/CD Pipelines",
          icon: "assets/icons/pipline.svg",
        },
      ],
    },
    {
      title: "Programming Languages",
      values: [
        {
          name: "Python",
          icon: "assets/icons/python.svg",
        },
        {
          name: "Javascript",
          icon: "assets/icons/js.svg",
        },
        {
          name: "Java",
          icon: "assets/icons/java.svg",
        },
      ],
    },
    {
      title: "Developer And Collaboration Tools",
      values: [
        {
          name: "Git",
          icon: "assets/icons/git.svg",
        },
        {
          name: "Github",
          icon: "assets/icons/github.svg",
        },
        {
          name: "Jira",
          icon: "assets/icons/jira.svg",
        },
        {
          name: "VS Code",
          icon: "assets/icons/vs-code.svg",
        },
        {
          name: "Slack",
          icon: "assets/icons/slack.svg",
        },
        {
          name: "Trello",
          icon: "assets/icons/trello.svg",
        },
        {
          name: "Confluence",
          icon: "assets/icons/confluence.svg",
        },
        {
          name: "Notion",
          icon: "assets/icons/notion.svg",
        },
        {
          name: "Figma",
          icon: "assets/icons/figma.svg",
        },
        {
          name: "Postman",
          icon: "assets/icons/postman.svg",
        },
      ],
    },
    {
      title: "AI_ML Frameworks",
      values: [
        {
          name: "Tensorflow",
          icon: "assets/icons/tensorflow.svg",
        },
        {
          name: "Keras",
          icon: "assets/icons/keras.svg",
        },
        {
          name: "Pytorch",
          icon: "assets/icons/pytorch.svg",
        },
        {
          name: "OpenCV",
          icon: "assets/icons/opencv.svg",
        },
        {
          name: "scikit-learn",
          icon: "assets/icons/scikit-learn.png",
        },
        {
          name: "Pandas",
          icon: "assets/icons/pandas.svg",
        },
        {
          name: "Numpy",
          icon: "assets/icons/numpy.svg",
        },
      ],
    },
    {
      title: "SoftSkills",
      values: [
        {
          name: "Productive",
          icon: "assets/icons/productive.png",
        },
        {
          name: "Efficient",
          icon: "assets/icons/efficient.png",
        },
        {
          name: "Collaborative",
          icon: "assets/icons/collaborative.png",
        },
        {
          name: "Abstract Thinking",
          icon: "assets/icons/thinking.png",
        },
        {
          name: "Problem-Solving",
          icon: "assets/icons/problem-solving.png",
        },
        {
          name: "Team Leadership",
          icon: "assets/icons/leadership.png",
        },
      ],
    },
    {
      title: "Languages Spoken",
      values: [
        {
          name: "English",
          icon: "assets/icons/english.png",
        },
        {
          name: "Kannada",
          icon: "assets/icons/kannada.png",
        },
        {
          name: "Hindi",
          icon: "assets/icons/hindi.png",
        },
        {
          name: "Telugu",
          icon: "assets/icons/telugu.png",
        },
      ],
    },
  ];

  return (
    <div className="min-h-screen transition-colors duration-300 bg-white dark:bg-neutral-900">
      {/* Mobile Image */}
      <div className="md:hidden relative right-[5%] z-10">
        <img
          className="h-[260px] w-[350px] object-cover"
          src="gaurav-org.webp"
          alt="Gaurav's Portrait"
        />
      </div>

      <HomeHeader />

      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[62.5%] bg-white dark:bg-neutral-900 text-neutral-800 dark:text-white flex flex-col items-center pt-3 md:pt-28 pb-10">
          {cards.map((item, index) => (
            <div key={index} className="mb-16">
              <BackgroundGradient className="rounded-[22px] max-w-lg p-4 sm:p-10 bg-white dark:bg-zinc-900">
                <h2 className="mb-5 text-2xl font-bold text-center text-blue-400 dark:text-blue-300">
                  {item.title}
                </h2>

                <img
                  className="rounded-lg w-[90%] md:w-[350px] h-[200px] mx-auto object-cover"
                  src={item.image}
                  alt={item.title}
                />

                <div className="p-4 mt-8 space-y-6 transition-colors duration-300 rounded-lg ">
                  {" "}
                  {item.points.map((point, idx) => (
                    <div
                      key={idx}
                      className="flex items-center gap-2 transition-colors duration-300 text-neutral-800 dark:text-neutral-200 hover:text-blue-500 dark:hover:text-blue-300"
                    >
                      {item.points.length > 1 && <ArrowForwardIcon />}
                      <p>{point}</p>
                    </div>
                  ))}
                </div>

                {item.buton && item.path && (
                  <button
                    onClick={() => navigate(item.path)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    className={`mt-5 text-blue-400 flex items-center transition-transform duration-300 hover:translate-x-1`}
                  >
                    {item.buton}
                  </button>
                )}
              </BackgroundGradient>
            </div>
          ))}
        </div>

        {/* Right Section - Skills */}
        <div className="w-full md:w-[37.5%] bg-white dark:bg-neutral-900 text-neutral-800 dark:text-white p-5 md:pt-5">
          {chips.map((section, idx) => (
            <div key={idx} className="mt-24 first:mt-0">
              <h2 className="mb-6 text-2xl font-bold text-blue-400 dark:text-blue-300">
                {section.title}
              </h2>

              <div className="flex flex-wrap gap-4">
                {section.values.map((skill, skillIdx) => (
                  <div key={skillIdx}>
                    <Modal>
                      <ModalTrigger className="relative flex justify-center p-3 text-white transition-all duration-300 rounded-lg bg-neutral-200 dark:bg-neutral-700 hover:bg-neutral-300 dark:hover:bg-neutral-600 group/modal-btn">
                        {" "}
                        <span className="text-sm font-medium text-center transition-all duration-500 text-neutral-800 dark:text-neutral-200 group-hover/modal-btn:translate-x-40 group-hover/modal-btn:opacity-0">
                          {" "}
                          {skill.name}
                        </span>
                        <div className="absolute inset-0 z-20 flex items-center justify-center text-white transition duration-500 -translate-x-40 group-hover/modal-btn:translate-x-0">
                          <img
                            src={skill.icon}
                            className="object-contain w-10 h-10"
                            alt={skill.name}
                          />
                        </div>
                      </ModalTrigger>
                    </Modal>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
