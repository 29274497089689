import React from "react";
import { BackgroundBeamsWithCollision } from "../ui/BackgroundBeam";

const HomeHeader = () => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;

  return (
        <div className="relative h-[40rem] overflow-hidden w-full bg-white dark:bg-[#333] transition-colors duration-300">
          <div className="absolute inset-0 z-10">
            <BackgroundBeamsWithCollision />
          </div>
    
          {/* Content Container */}
          <div className="container h-full px-4 mx-auto">
            <div className="relative z-20 flex flex-wrap items-center h-full pt-4">
              {/* Text Content */}
              <div className="w-full transition-colors duration-300 md:w-1/2 text-neutral-800 dark:text-white">
                <h1
                  className={`font-serif ${
                    isMobile ? "text-[1.8rem]" : "text-[3.2rem]"
                  } mb-3`}
                >
                  Hi there!
                  <br /> I'm Gaurav Shivaprasad...✨
                </h1>
                <p className="mb-2">
                  A dedicated graduate student pursuing a Master's in Computer
                  Science at the Illinois Institute of Technology.
                </p>
                <p className="mb-2">
                  Passionate about developing impactful software solutions and
                  collaborating with teams to solve real-world problems using
                  cutting-edge technology.
                </p>
                <p>
                  I am a tech enthusiast with a strong background in software
                  engineering and mobile application development.
                </p>
              </div>
    
              {/* Image - Hidden on mobile */}
              {!isMobile && (
                <div className="flex items-center justify-center w-full md:w-1/2">
                  <img
                    src="gaurav-org.webp"
                    alt="Gaurav Shivaprasad"
                    className="h-[360px] w-[450px] object-cover rounded-lg shadow-lg"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
  );
};

export default HomeHeader;
