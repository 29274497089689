import { Grid, Typography, IconButton, Box, Button } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useNavigate } from "react-router-dom";
import { IconBrandLinkedin, IconBrandGithub } from "@tabler/icons-react";

export default function Footer() {
  const navigate = useNavigate();

  // Navigation functions
  const goToPage = (path) => navigate(path);

  // Render Quick Links
  const renderQuickLinks = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography sx={{ color: "powderblue" }}>Quick Links</Typography>
      <Button
        onClick={() => goToPage("/project")}
        sx={{
          marginTop: "20px",
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Project
      </Button>
      <Button
        onClick={() => goToPage("/achievements")}
        sx={{
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Achievements
      </Button>
      <Button
        onClick={() => goToPage("/contact")}
        sx={{
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Contact Me
      </Button>
    </Box>
  );

  // Render Social Links
  const renderSocialLinks = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        color: "powderblue",
      }}
    >
      <Typography sx={{ paddingBottom: "10px" }}>Social</Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <a
          href="https://www.linkedin.com/in/gauravs-2k/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          <IconButton>
            <LinkedInIcon />
          </IconButton>
        </a>
        <a
          href="https://github.com/Gauravs-2k"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          <IconButton>
            <GitHubIcon />
          </IconButton>
        </a>
      </Box>
    </Box>
  );

  // Render Footer
  return (
    <footer className="w-full px-5 py-10 pt-10 transition-colors duration-300 bg-neutral-50 dark:bg-neutral-800">
      <div className="container grid grid-cols-1 gap-8 mx-auto md:grid-cols-2">
        {/* Left Section */}
        <div className="space-y-6">
          <button
            onClick={() => goToPage("/")}
            className="text-2xl font-bold text-blue-600 transition-colors dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
          >
            Gaurav
          </button>

          <p className="text-sm text-neutral-600 dark:text-neutral-400">
            Master's student in Computer Science at Illinois Institute of
            Technology with a background in software development and
            entrepreneurship. I have founded Bytecrafts, led projects in
            healthcare, education, and finance, and developed innovative
            solutions like real-time sign language translation and automated
            plant robots. My passion lies in creating impactful technology
            solutions that improve lives.
          </p>
        </div>

        {/* Right Section */}
        <div className="flex justify-center gap-16">
          {/* Quick Links */}
          <div className="flex flex-col items-center space-y-4">
            <h3 className="font-medium text-blue-600 dark:text-blue-400">
              Quick Links
            </h3>
            <button
              onClick={() => goToPage("/project")}
              className="transition-colors text-neutral-600 dark:text-neutral-400 hover:text-blue-600 dark:hover:text-blue-400"
            >
              Project
            </button>
            <button
              onClick={() => goToPage("/achievements")}
              className="transition-colors text-neutral-600 dark:text-neutral-400 hover:text-blue-600 dark:hover:text-blue-400"
            >
              Achievements
            </button>
            <button
              onClick={() => goToPage("/contact")}
              className="transition-colors text-neutral-600 dark:text-neutral-400 hover:text-blue-600 dark:hover:text-blue-400"
            >
              Contact Me
            </button>
          </div>

          {/* Social Links */}
          <div className="flex flex-col items-center space-y-4">
            <h3 className="font-medium text-blue-600 dark:text-blue-400">
              Social
            </h3>
            <div className="flex gap-4">
              <a
                href="https://www.linkedin.com/in/gauravs-2k/"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-colors text-neutral-600 dark:text-neutral-400 hover:text-blue-600 dark:hover:text-blue-400"
              >
                <IconBrandLinkedin className="w-6 h-6" />
              </a>
              <a
                href="https://github.com/Gauravs-2k"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-colors text-neutral-600 dark:text-neutral-400 hover:text-blue-600 dark:hover:text-blue-400"
              >
                <IconBrandGithub className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
