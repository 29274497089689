import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FloatingBar from "./components/FloatingBar";
import { IconBrandLinkedin, IconBrandGithub } from "@tabler/icons-react";

const Header = () => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery("(max-width:600px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const goToPage = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <div className="relative">
      <header className="sticky top-0 z-50 w-full transition-colors duration-300 border-b border-neutral-200 dark:border-neutral-800 bg-neutral-50/75 dark:bg-neutral-900 backdrop-blur-sm">
        <div className="container flex items-center justify-between h-16 px-4 mx-auto">
          {/* Mobile Menu Button */}
          {isMobileView && (
            <button
              onClick={toggleMenu}
              className="p-2 transition-colors text-neutral-600 dark:text-neutral-300 hover:text-neutral-900 dark:hover:text-white"
              aria-label="Toggle menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    isMenuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          )}

          {/* Logo */}
          <button
            onClick={() => goToPage("/")}
            className="text-2xl font-bold transition-colors duration-300 font-times text-neutral-900 dark:text-neutral-100 hover:text-neutral-700 dark:hover:text-neutral-300"
          >
            Gaurav's Portfolio
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMobileView && (
        <div
          className={`fixed pt-20 inset-y-0 left-0 z-40 w-64 bg-neutral-50 dark:bg-neutral-800 transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <nav className="flex flex-col h-full py-6">
            <div className="flex-1 space-y-2">
              <button
                onClick={() => goToPage("/")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Home
              </button>
              <button
                onClick={() => goToPage("/project")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Project
              </button>
              <button
                onClick={() => goToPage("/work")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Work
              </button>
              <button
                onClick={() => goToPage("/achievements")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Achievements
              </button>
              <button
                onClick={() => goToPage("/contact")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Contact Me
              </button>
              <button
                onClick={() => goToPage("/coding")}
                className="w-full px-4 py-2 text-left transition-colors text-neutral-800 dark:text-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              >
                Coding
              </button>
            </div>

            {/* Social Icons */}
            <div className="flex gap-4 px-4">
              <IconBrandLinkedin className="w-6 h-6 text-neutral-600 dark:text-neutral-400" />
              <IconBrandGithub className="w-6 h-6 text-neutral-600 dark:text-neutral-400" />
            </div>
          </nav>
        </div>
      )}

      {/* Backdrop */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-30 bg-black/20 dark:bg-black/40 backdrop-blur-sm"
          onClick={toggleMenu}
        />
      )}

      {/* Desktop Navigation */}
      {!isMobileView && <FloatingBar />}
    </div>
  );
};

export default Header;
